<template>
  <div class="prompts-list">
    <spinner v-if="loading" />
    <ul v-else>
      <PromptListItems
        :categoryName="item.label"
        :categoryId="item.value"
        v-for="item in filterCategories"
        :key="item.value"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PromptListItems from "./PromptListItems.vue";
import Spinner from "./Spinner.vue";

export default {
  name: "PromptList",
  components: {
    PromptListItems,
    Spinner,
  },
  props: {
    module: {
      type: String,
      default: "1",
    },
    page_id: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["getModulePrompts"]),
    filterCategories() {
      const filter = [];
      const categories = [];
      this.getModulePrompts.map(({ category }) => {
        if (!filter.includes(category.value)) {
          filter.push(category.value);
          categories.push(category);
        }
      });
      return categories;
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch("loadModulePrompts", [
      this.module,
      this.page_id,
      () => {
        this.loading = false;
      },
    ]);
  },
};
</script>
