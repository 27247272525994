<template>
  <div>
    <h5 class="prompt-category-header">{{ categoryName }}</h5>
    <PromptListItem v-for="item in filterPrompts" :key="item.ID" :item="item" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PromptListItem from "./PromptListItem";

export default {
  components: { PromptListItem },
  props: {
    categoryId: {
      type: String,
    },
    categoryName: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["getModulePrompts"]),
    filterPrompts() {
      return this.getModulePrompts.filter(
        (prompt) => prompt.category.value === this.categoryId
      );
    },
  },
};
</script>
